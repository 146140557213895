<template>
  <div class="textbox">
    <div class="bottombox">
      <div class="bottom_left">
        <div class="tabbox">
          <div class="tabtitle">个人中心</div>

          <div
            class="tabbtn"
            :class="i == 1 || i == 3 ? 'active' : ''"
            @click="gettags(1)"
          >
            - 个人首页
          </div>

          <div class="tabtitle">安全中心</div>

          <div
            class="tabbtn"
            :class="i == 2 || i == 4 ? 'active' : ''"
            @click="gettags(2)"
          >
            - 账户设置
          </div>
        </div>
      </div>

      <!-- 添加企业基本信息 -->
      <div class="bottom_right" v-if="i == 1">
        <div class="heard">
          <span @click="getback()"
            ><img src="../assets/img/back.png" alt="" /> 返回</span
          >
        </div>
        <div class="smalltitle">填写企业基本信息</div>
        <el-form
          :model="ruleFormC"
          :rules="ruleC"
          ref="ruleFormC"
          class="demo-ruleForm"
        >
          <el-form-item label="企业名称" prop="Name">
            <el-input v-model="ruleFormC.Name"></el-input>
          </el-form-item>

          <!-- <el-form-item label="是否自贸专栏企业：" prop="Type">
          <el-radio-group v-model="ruleFormC.Type" @change="getradio">
            <el-radio v-model="ruleFormC.Type" label="1">是</el-radio>
            <el-radio v-model="ruleFormC.Type" label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
          <el-form-item label="统一社会信用代码" prop="SocialNo">
            <el-input v-model="ruleFormC.SocialNo"></el-input>
          </el-form-item>
          <el-form-item label="法人" prop="LegalPerson">
            <el-input v-model="ruleFormC.LegalPerson"></el-input>
          </el-form-item>
          <el-form-item label="成立时间" prop="OpenTime">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ruleFormC.OpenTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="企业类型" prop="CompanyTypeName">
            <el-select
              v-model="ruleFormC.CompanyTypeName"
              @change="changeType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in companyType"
                :key="item.IndexID"
                :label="item.Name"
                :value="item.SysID"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业行业" prop="CompanyProfessionName">
            <el-select
              v-model="ruleFormC.CompanyProfessionName"
              @change="changeProfession"
              placeholder="请选择"
            >
              <el-option
                v-for="item in companyProfession"
                :key="item.IndexID"
                :label="item.Name"
                :value="item.SysID"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="ContactPerson">
            <el-input v-model="ruleFormC.ContactPerson"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="Tel">
            <el-input v-model="ruleFormC.Tel"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="Email">
            <el-input v-model="ruleFormC.Email"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="Address">
            <el-input v-model="ruleFormC.Address"></el-input>
          </el-form-item>
          <el-form-item label="企业标签" prop="Tags">
            <el-select v-model="ruleFormC.Tags" multiple placeholder="请选择">
              <el-option
                v-for="item in companyTags"
                :key="item.value"
                :label="item.label"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 营业执照  base64  -->
          <el-form-item label="营业执照">
             <span slot="label" ><span style="color:red;font-size:12px">*</span> 营业执照</span>
            <template>
              <div v-if="xxzzlist.length > 0">
                <div
                  class="demo-upload-list"
                  :key="index"
                  v-for="(item, index) in xxzzlist"
                >
                  <template style="height: 150px; width: 150px">
                    <!-- <img :src="item.url" />
                    <div class="demo-upload-list-cover">
                      <i class="el-icon-delete"
                         @click="RemoveYYZZ(item)"></i>
                    </div> -->

                    <div class="demo-upload-list-cover">
                      <img :src="item.url" class="imputImg" />
                      <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                      <i
                        class="el-icon-circle-close delimg"
                        @click="RemoveYYZZ(item)"
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <Upload> -->
              <el-upload
                ref="upload"
                :before-upload="AddFileYYZZ"
                type="drag"
                action=""
                style="display: inline-block; width: 100%"
              >
                <div style="width: 58px; height: 58px; line-height: 58px">
                  <i class="el-icon-camera-solid"></i>
                </div>
              </el-upload>
              <!-- </Upload> -->
            </template>
          </el-form-item>
          <!-- 企业LOGO -->
          <el-form-item label="上传企业logo">
            <span slot="label"
              >  <span style="color:red;font-size:12px">*</span>上传企业logo<span style="color: red; font-size: 12px">
                (建议尺寸80x80)</span
              ></span
            >
            <template>
              <div v-if="comLogolist.length > 0">
                <div
                  class="demo-upload-list"
                  :key="index"
                  v-for="(item, index) in comLogolist"
                >
                  <template style="height: 150px; width: 150px">
                    <div class="demo-upload-list-cover">
                      <img :src="item.url" class="imputImg" />
                      <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                      <i
                        class="el-icon-circle-close delimg"
                        @click="RemoveLogo(item)"
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <Upload> -->
              <el-upload
                ref="upload"
                v-if="comLogolist.length == 0"
                :before-upload="AddComLogo"
                type="drag"
                action=""
                style="display: inline-block; width: 58px"
              >
                <div style="width: 58px; height: 58px; line-height: 58px">
                  <i class="el-icon-camera-solid"></i>
                  <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
                </div>
              </el-upload>
              <!-- </Upload> -->
            </template>
          </el-form-item>

          <el-form-item prop="introduce" label="企业介绍">
            <editor-bar
              v-model="ruleFormC.Description"
              :value="ruleFormC.Description"
              @change="getEditor"
              ref="weditor"
            ></editor-bar>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleFormC')"
              >确定</el-button
            >
            <el-button @click="getback()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// 公共样式
import EditorBar from "../components/wangeditor";
import timeFormat from "../util/time.js";
var isvalidPhone = function (str) {
  const reg = /^(1[3456789]\d{9})$|^(0\d{2,3}-\d{7,8})$/;
  return reg.test(str);
};
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidPhone(value)) {
    callback(new Error("请输入正确的11位手机号码或座机号码"));
  } else {
    callback();
  }
};
export default {
  components: {
    EditorBar,
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleFormC.checkPass !== "") {
          this.$refs.ruleFormC.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleFormC.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      DeleteImgSysID: "",
      checkUpload: 1,
      imgFilesList: [],
      FileList: [],
      LogoList: [],
      comLogolist: [],
      xxzzlist: [],
      iscarrie: "",
      compObj: [],
      options: [
        {
          value: "选项1",
          label: "男",
        },
        {
          value: "选项2",
          label: "女",
        },
        {
          value: "选项3",
          label: "其他",
        },
      ],
      value: "",
      index: 1,
      i: 1,
      dialogVisible: false,
      rules: {
        pass: "",
        checkPass: "",
        age: "",
      },
      ruless: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      ruleForm: {},
      // 企业
      // 营业执照
      yyzzlist: [],

      // 企业类型列表
      companyType: null,
      // 企业标签
      companyTags: null,
      ruleFormC: {
        Type: "0",
      },
      ruleC: {
        Name: [
          // 企业名称
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {},
        ],
        Type: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        // 统一社会信用代码
        SocialNo: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        // 法人
        LegalPerson: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 成立时间
        OpenTime: [],
        // 企业类型
        CompanyTypeName: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        // 企业行业
        CompanyProfessionName: [
          {
            required: true,
            message: "请选择企业行业",
            trigger: "change",
          },
        ],
        // 联系人
        ContactPerson: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 联系电话
        Tel: [{ required: true, trigger: "blur", validator: validPhone }],
        // 邮箱
        Email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        // 地址
        Address: [{ required: true, message: "请填写地址", trigger: "blur" }],
        // 企业标签
        Tags: [],
      },
      WechatImg: "",
      companyProfession: [],
    };
  },

  methods: {
    getradio(e) {
      console.log(e);
    },
    changeType(val) {
      console.log(val);
      this.ruleFormC.CompanyTypeSysID = val;
    },
    changeProfession(val) {
      this.ruleFormC.CompanyProfessionSysID = val;
    },
    getWeChat() {
      let userid = window.localStorage.getItem("userId");
      let WechatImg =
        "http://andywlx.wechat.ngrok2.xiaomiqiu.cn/wechatfun/GenQRCode?userid=" +
        userid;
      this.WechatImg = WechatImg;
      this.i = 6;
    },
    getComVIP() {
      this.i = 5;
    },
    // 企业标签
    getTags() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyTag",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyTags = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 企业行业
    getProfession() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CompanyProfession",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyProfession = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 企业富文本
    getEditor(value) {
      console.log(value);

      this.ruleFormC.Description = value;
    },
    // 企业类型
    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "NewsCompanyType",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyType = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //附件列表
    LoadFiles(sysid) {
      if (sysid == "") return;
      this.loading = true;
      let that = this;
      that
        .$post("/api/File/List", {
          MainSysID: sysid,
        })
        .then(function (res) {
          let List = [];
          List = res.Obj;

          List.forEach((i) => {
            if (i.Category == "YYZZ") {
              that.xxzzlist.push({
                name: i["SourceName"] + "." + i["Extension"],
                url: that.urlOA + i["URL"].substr(1),
                size: -1,
                SysID: i.SysID,
              });
            }
            if (i.Category == "Logo") {
              that.comLogolist.push({
                name: i["SourceName"] + "." + i["Extension"],
                url: that.urlOA + i["URL"].substr(1),
                size: -1,
                SysID: i.SysID,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //上传logo附件
    AddFileLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.LogoList = this.LogoList.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },

    //上传营业执照附件
    AddFileYYZZ(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.xxzzlist = this.xxzzlist.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    //上传企业logo
    AddComLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.comLogolist = this.comLogolist.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    //删除logo
    RemoveYYZZ(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.xxzzlist = this.xxzzlist.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.xxzzlist = this.xxzzlist.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    //删除logo
    RemoveLogo(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.comLogolist = this.comLogolist.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.comLogolist = this.comLogolist.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    // 提交企业信息
    submitForm(formName) {
      let that = this;
      let userid = window.localStorage.getItem("userId");
      let ruleForm = that.ruleFormC;
      ruleForm.Type=0
      that.$refs[formName].validate((valid) => {
        if (valid) {
          ruleForm.UserSysID = userid;
          ruleForm.State = 0;
          // 企业logo
          let comLogolist = that.comLogolist;
          if (comLogolist.length == 0) {
            that.$message.error("请上传企业Logo");

            return;
          }
          console.log(comLogolist);
          let formData = new FormData();
          comLogolist.forEach((file) => {
            formData.append("Logo", file);
          });

          // 营业执照
          let xxzzlist = that.xxzzlist;
          if (xxzzlist.length == 0) {
            that.$message.error("请上传营业执照");
            return;
          }
          console.log(xxzzlist);

          xxzzlist.forEach((file) => {
            formData.append("YYZZ", file);
          });
          console.log(ruleForm.Tags);
          if (ruleForm.Tags && ruleForm.Tags.length > 1) {
            ruleForm.Tags = ruleForm.Tags.join(",");
          } else if (ruleForm.Tags && ruleForm.Tags.length == 1) {
            ruleForm.Tags = ruleForm.Tags[0];
          } else {
            ruleForm.Tags = "";
          }
          formData.append("Obj", JSON.stringify(ruleForm));
          formData.append("DeleteImgSysID", this.DeleteImgSysID);
          that
            .$post("/api/Company/CompanyEdit", formData)
            .then(function (data) {
              console.log(data);
              if (data.Result) {
                that.$message.success("提交成功");
                that.ruleFormC = {};
               
                setTimeout(() => {
                  that.$router.go(-1);
                }, 2000);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消编辑
    cancleserve() {
      let that = this;
      that.i = 1;
      that.item = {};
    },
    getindex() {
      window.localStorage.setItem("index", "1");
      this.$router.push("/");
    },
    gettags(i) {
      this.i = i;
    },
    getback() {
      this.$router.go(-1);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getcompany() {
      this.$router.push("/companyCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },

    maxUploadNum(file, FileList) {
      console.log(file);
      let imgLength = FileList.length,
        uploadBtn = document.getElementsByClassName("el-upload")[0];
      if (imgLength >= 1) {
        uploadBtn.style.display = "none";
      }
    },
    // 获取个人信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.CompanyObj;
          // 图片处理
          company.Logo = that.urlOA + company.Logo.substr(1);
          // 时间处理
          if (company.OpenTime) {
            company.OpenTime = timeFormat.timeFormat(company.OpenTime, 1);
          }

          if (company.Tags && company.Tags.indexOf(",") != -1) {
            company.Tags = company.Tags.split(",");
          } else if (company.Tags && company.Tags.indexOf(",") == -1) {
            let arr = [];
            arr.push(company.Tags);
            company.Tags = arr;
          }
          company.Type = company.Type + "";
          that.ruleFormC = company;
          that.compObj = company;
          console.log(EditorBar.value);
          // EditorBar.value = that.ruleForm.Description
          that.LoadFiles(company.SysID);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getTags();
    this.getProfession();
    this.gettype();
    this.getnewsList();
  },
};
</script>


<style scoped  lang="scss">
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}
.top_btnbox {
  position: relative;
  top: 0;
  float: left;
  margin-left: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  // background-color: red;
  em {
    position: absolute;
    font-style: none;
    height: 20px;
    line-height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  img {
    position: absolute;
    height: 40px;
    width: 40px;
    left: 30px;
    top: 10px;
    margin: 0;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
    height: 30px;
    line-height: 30px;
    color: #1890ff;
    font-size: 15px;
  }
}
.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 6px 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
  .img {
    display: inline-block;
    height: 300px;
    width: 300px;
    margin-left: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
  }
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid blue;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  // height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.userinforbox {
  width: 100%;
  // padding: 15px 0;
  border: 1px solid #d9edf7;
  box-sizing: border-box;
}
.username {
  // height: 80px;
  line-height: 80px;
  font-size: 14px;
  padding: 0 15px;
  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
}

.username:nth-child(2n + 1) {
  background-color: #eff7fc;
  color: #666;
}

.userlogo {
  height: 100px;
  padding: 0 15px;
  line-height: 100px;
  font-size: 14px;
  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
  img {
    height: 80px;
    width: 80px;
    vertical-align: middle;
  }
}
.upbox {
  margin-top: 10px;
  span {
    cursor: pointer;
    background-color: #50bfff;
    padding: 6px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.bottom_right::v-deep .el-form-item__content {
  width: 80%;

  margin-top: 60px;
  img {
    float: left;
    padding-bottom: 30px;
  }
  .delimg {
    float: left;
    margin-top: -15px;
    margin-left: -15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9999999999;
    border-radius: 50%;
    background-color: red;
  }
}
.el-icon-camera-solid:before {
  font-size: 24px;
}
@media (max-width: 500px) {
  .heard {
    position: relative;
    height: 30px;
    width: 100%;
    span {
      cursor: pointer;
      position: absolute;
      height: 30px;
      width: 100px;
      border-radius: 3px;
      color: #1890ff;
      font-size: 14px;
      line-height: 32px;
      background-color: #fff;
      vertical-align: middle;
      img {
        display: inline-block;
        height: 30px;
        width: 30px;
        // vertical-align:middle;
        vertical-align: middle;
      }
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .textbox {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f6f6f6;
  }
  .topbox {
    width: 100%;
    background-color: #fff;
    height: 100px;
    margin-top: 30px;
  }
  .topboxinfor {
    width: 1200px;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    // background-color: aquamarine;
  }
  .topboxinfor img {
    float: left;
    height: 60px;
    margin-top: 20px;
    margin-right: 40px;
  }
  .top_btnbox {
    position: relative;
    top: 0;
    float: left;
    margin-left: 20px;
    height: 100px;
    width: 100px;
    cursor: pointer;
    // background-color: red;
    em {
      position: absolute;
      font-style: none;
      height: 20px;
      line-height: 20px;
      width: 20px;
      right: 10px;
      top: 10px;
      border-radius: 50%;
      background-color: red;
      font-size: 10px;
      color: #fff;
      text-align: center;
    }
    img {
      position: absolute;
      height: 40px;
      width: 40px;
      left: 30px;
      top: 10px;
      margin: 0;
    }
    span {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 60px;
      height: 30px;
      line-height: 30px;
      color: #1890ff;
      font-size: 15px;
    }
  }
  .bottombox {
    width: 100%;
    min-height: 60px;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    // background-color: aquamarine;
    overflow: hidden;
  }
  .bottom_left {
    float: left;
    // background-color: red;
    width: 100%;
    min-height: 10px;
  }
  .bottom_right {
    float: left;
    width: 100%;
    margin-left: 0;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 600px;
    box-sizing: border-box;
    .img {
      display: inline-block;
      height: 300px;
      width: 300px;
      margin-left: 50%;
      margin-top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .tabbox {
    display: none;
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabtitle {
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
  .active {
    border-left: 3px solid blue;
    background-color: #f6f6f6;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    // height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .userinforbox {
    width: 100%;
    // padding: 15px 0;
    border: 1px solid #d9edf7;
    box-sizing: border-box;
  }
  .username {
    // height: 80px;
    line-height: 80px;
    font-size: 14px;
    padding: 0 15px;
    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
  }

  .username:nth-child(2n + 1) {
    background-color: #eff7fc;
    color: #666;
  }

  .userlogo {
    height: 100px;
    padding: 0 15px;
    line-height: 100px;
    font-size: 14px;
    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
    img {
      height: 80px;
      width: 80px;
      vertical-align: middle;
    }
  }
  .upbox {
    margin-top: 10px;
    span {
      cursor: pointer;
      background-color: #50bfff;
      padding: 6px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 6;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .bottom_right::v-deep .el-form-item__content {
    width: 100%;

    margin-top: 60px;
    img {
      float: left;
      padding-bottom: 30px;
    }
    .delimg {
      float: left;
      margin-top: -15px;
      margin-left: -15px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 9999999999;
      border-radius: 50%;
      background-color: red;
    }
  }
  .el-icon-camera-solid:before {
    font-size: 24px;
  }
}
</style>